<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card class="mt-1">
      <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequuntur porro dicta assumenda veritatis vel nisi?
        Minima nihil similique deleniti repudiandae iusto quia quas cumque obcaecati! Dignissimos odio illum deleniti
        tempora.</p>
      <b-form class="mt-3">
        <b-row>
          <b-col
            sm="12"
            xl="6"
            class="col-xxl-4"
          >
            <b-form-group
              label-for="group"
              :label="$t('forex.group')"
            >
              <validation-provider
                #default="{ errors }"
                name="group"
                vid="group"
                rules="required"
              >
                <v-select
                  id="group"
                  v-model="groupSelected"
                  :options="group"
                  label="name"
                  @input="onchangeGroup"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            xl="6"
            class="col-xxl-4"
          >
            <b-form-group
              :label="$t('forex.leverage')"
              label-for="leverage"
            >
              <validation-provider
                id="leverage"
                #default="{ errors }"
                name="leverage"
                vid="leverage"
                rules="required"
              >
                <v-select
                  id="leverage"
                  v-model="leverageSelected"
                  :options="leverage"

                  label="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1"
              @click="save"
            >

              <span v-if="!submitLoading">{{ $t('fiatWidraw.save_changes') }}</span>
              <div v-if="submitLoading">
                <b-spinner
                  small
                  type="grow"
                />
                <span>{{ $t('loading') }}</span>
              </div>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BForm,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import MetatraderApis from '@/modules/forex/service/apis'

const metatrader = new MetatraderApis()
export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BForm,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      group: [],
      leverage: [],
      show: true,
      leverageSelected: '',
      groupSelected: '',
      submitLoading: false,

    }
  },
  async mounted() {
    await this.getData()
    await this.getGroups()
  },
  methods: {
    async getData() {
      await metatrader.accountSetting(this.$route.params.platform, this.$route.params.id).then(res => {
        this.groupSelected = res.data.results.group
        this.leverageSelected = res.data.results.leverage
      })
    },
    async getGroups() {
      await metatrader.getAllGroups({ platform: this.$route.params.platform }).then(res => {
        this.group = res.data.results
      }).finally(() => {
        this.show = false
      })
    },
    async save() {
      this.submitLoading = true
      const data = {
        platform: this.$route.params.platform, account: this.$route.params.id, leverage_id: this.leverageSelected.id, group_id: this.groupSelected.id,
      }
      await metatrader.saveSetting(data).then(res => {
        this.$swal({
          title: res.data.message,
          icon: 'success',
          timer: 2500,
          showConfirmButton: false,
        })
      }).finally(() => {
        this.submitLoading = false
      })
    },
    onchangeGroup(group) {
      this.leverage = this.group.filter(item => item.name === group.name)[0].leverages
    },
  },
}
</script>
